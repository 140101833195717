import { createRouter, createWebHistory } from 'vue-router'

import moduleWebsite from '@/modules/website/router/index.js'
import moduleBff from '@/modules/bff/router/index.js'
import moduleAdmin from '@/modules/admin/router/index.js'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL || '/'),
  routes: [...moduleAdmin, ...moduleWebsite, ...moduleBff],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, _, next) => {
  if (!to?.meta?.public) {
    if (!localStorage.getItem('userData')) {
      //return next({ name: 'signin' })
    }
  }
  return next()
})

export default router
