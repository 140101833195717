export default [
  {
    path: '/bo/dashboard',
    name: 'dashboard-bo',
    meta: { layout: 'admin' },
    component: () => import('../pages/dasboard/index.vue')
  },
  {
    path: '/admin/customers',
    name: 'customers-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/customers/index.vue')
  },
  {
    path: '/admin/customer/:id',
    name: 'customers-edit',
    meta: { layout: 'admin' },
    component: () => import('../pages/customers/edit.vue')
  },
  {
    path: '/admin/products',
    name: 'products-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/products/index.vue')
  },
  {
    path: '/admin/product/:id',
    name: 'products-view',
    meta: { layout: 'admin' },
    component: () => import('../pages/products/edit.vue')
  },
  {
    path: '/admin/plataform-terms',
    name: 'plataform-terms-list',
    meta: { layout: 'admin' },
    component: () => import('../pages/plataformTerms/index.vue')
  },
  {
    path: '/admin/plataform-terms/:id',
    name: 'plataform-terms-view',
    meta: { layout: 'admin' },
    component: () => import('../pages/plataformTerms/edit.vue')
  }
]
