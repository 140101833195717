export default [
  {
    path: '/dashboard',
    name: 'dashboard-bff',
    meta: { layout: 'bff' },
    component: () => import('../pages/dasboard/index.vue')
  },
  {
    path: '/journey',
    name: 'journey',
    meta: { layout: 'bff' },
    component: () => import('../pages/journey/index.vue')
  },
  {
    path: '/afiliado',
    name: 'dashboard-affiliate',
    meta: { layout: 'bff' },
    component: () => import('../pages/dasboard-affiliate/index.vue')
  }
]
