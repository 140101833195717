<template>
  <div className="horizontalMenucontainer">
    <switcher />

    <div className="page">
      <c-header />

      <c-sidebar :menu-data-items="menuData" />

      <div className="main-content app-content">
        <div className="container-fluid">
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </div>
      </div>

      <c-footer />
    </div>

    <c-back-to-top />
  </div>
</template>

<script>
import Switcher from "@/shared/components/switcher/switcher.vue";
import CHeader from '@/shared/components/CHeader/index.vue';
import CSidebar from "@/shared/components/CSidebar/index.vue";
import CBackToTop from "@/shared/components/CBackToTop/index.vue";
import CFooter from "@/shared/components/CFooter/index.vue";

import { menuDataBO } from '@/data/menuDataBO.js';
export default {
  name: 'LayoutAdmin',
  components: {
    Switcher,
    CHeader,
    CSidebar,
    CBackToTop,
    CFooter
  },
  computed: {
    menuData() {
      return menuDataBO
    }
  }
}
</script>