export let notificationList = [
    {
      id: 1,
      icon: ``,
      isOnline: true,
      title: 'Congratulate Olivia James for New template start',
      time: 'Oct 15 12:32pm'
    },
    {
      id: 2,
      icon: ``,
      isOnline: false,
      title: 'Joshua Gray New Message Received',
      time: 'Oct 13 02:56am'
    },
    {
      id: 3,
      icon: ``,
      isOnline: true,
      title: 'Elizabeth Lewis added new schedule realease',
      time: 'Oct 12 10:40pm'
    },
    {
      id: 4,
      icon: ``,
      isOnline: true,
      title: 'Delivered Successful to Micky',
      time: 'Yesterday at 01:58am'
    },
    {
      id: 5,
      icon: ``,
      isOnline: true,
      title: 'You got 22 requests form Facebook',
      time: 'Today at 08:08pm'
    }
  ],
  cartList = [
    {
      id: 1,
      img: ``,
      title: 'Smart Watch',
      categoryList: [{ qty: '1' }, { status: 'In Stock', statusBgColor: 'text-success' }],
      price: '1,299.00'
    },
    {
      id: 2,
      img: ``,
      title: 'Flowers',
      categoryList: [{ qty: '2' }, { value: 'Free shipping', valueBgColor: 'bg-pink-transparent' }],
      price: '179.29'
    },
    {
      id: 3,
      img: ``,
      title: 'Running Shoes',
      categoryList: [{ qty: '4' }, { status: 'Out Stock', statusBgColor: 'text-danger' }],
      price: '29.00'
    },
    {
      id: 4,
      img: ``,
      title: 'Furniture',
      categoryList: [
        { value: 'Gray', valueBgColor: '' },
        { value: '50LB', valueBgColor: '' }
      ],
      price: '4,999.00'
    },
    {
      id: 5,
      img: ``,
      title: 'Tourist Bag',
      categoryList: [{ qty: '1' }, { status: 'In Stock', statusBgColor: 'text-success' }],
      price: '129.00'
    }
  ]
