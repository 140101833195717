<template>
  <footer class="footer mt-auto py-3 bg-white text-center">
    <div class="container">
      <span class="text-muted"> Copyright © <span id="year">2024</span> <a
          href="javascript:void(0);"
          class="text-dark fw-semibold"
        ></a>.
        Todos os direitos reservados
      </span>
    </div>
  </footer>
</template>
<script>
export default {
  name: "CFooter",
};
</script>
