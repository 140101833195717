<template>
  <custom-switcher />

  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>

  <c-back-to-top />
</template>
<script>

import CustomSwitcher from '@/shared/components/customswitcher/customswitcher.vue';
import CBackToTop from "@/shared/components/CBackToTop/index.vue";

export default {
  name: 'LayoutEmpty',
  components: {
    CustomSwitcher,
    CBackToTop
  },
  beforeCreate() {
    document.body.classList.add("landing-body");
  },
  unmounted() {
    document.body.classList.remove("landing-body");
  },
}
</script>