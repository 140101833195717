<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import LayoutEmpty from '@/core/layouts/LayoutEmpty.vue'
import LayoutAdmin from '@/core/layouts/LayoutAdmin.vue'
import LayoutBff from '@/core/layouts/LayoutBff.vue'


export default {
  name: 'StartedApp',
  components: {
    empty: LayoutEmpty,
    admin: LayoutAdmin,
    bff: LayoutBff
  },
  computed: {
    layout() {
      return this.$route?.meta?.layout ?? 'empty'
    }
  }
}
</script>